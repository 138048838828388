.intro{
    display: flex;
    height: 77vh;
    margin: 6rem;
}
.i-left{
    flex: 1;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.i-name{
    display: flex;
    flex-direction: column;
}
.i-name>:nth-child(1){
    color: black;
    font-size: 3rem;
    font-weight: bold;
}
.i-name>:nth-child(2){
    color: var(--orange);
    font-size: 3rem;
    font-weight: bold;
}
.i-name>:nth-child(3){
    font-weight: 100px;
    font-size: 14px;
    color: var(--gray);
    margin-top: 10px;
}

.i-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 1.2rem;
    margin-top: 20px;
}
.i-icons{
    display: flex;
    margin-top: 3rem;
    gap: 0rem;
}
.i-icons>*{
    transform: scale(0.5);
}
.i-icons>*:hover{
    cursor: pointer;
}
















.i-right{
    flex: 1;
    position: relative;
}

.i-right>*{
    position: absolute;
    z-index: 1;
}

.i-right>:nth-child(1){
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}
.i-right>:nth-child(2){
    top: -4.6rem;
    transform: scale(0.67);
    left: -3rem;
}
.i-right>:nth-child(3){
    transform: scale(1.4);
    left: 28%;

}
.i-right>:nth-child(4){
    transform: scale(0.6);
    top: -18%;
    left: -24%;
    border-radius: 50%;
    padding: 0;
}

.blur{
    position: absolute;
    width: 22rem;
    height: 4rem;
    border-radius: 50%;
    background: #25115c;
    z-index: -9;
    top: 18%;
    left: 56%;
    filter: blur(72px);
}


@media screen and (max-width:480px) {
    .i-name{
        margin-left: -80px;
    }
    .i-button{
        margin-left: -60px;
    }
    .intro {
        flex-direction: column;
        gap: 7rem;
        height: 64rem;
      }
    
      .i-right {
        transform: scale(0.8);
        left: -3rem;
      }
      .i-right .blur {
        display: none;
      }
      .floating-div:nth-of-type(1){
        /* display: none; */
        top: -7rem !important;;
      }

      .i-icons{
        margin-left: -100px;
      }
}