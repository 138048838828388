:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

.button {
    background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
  }
  
  .button:hover {
    background: white;
    cursor: pointer;
    border: 1px solid var(--orange);
    color: var(--orange);
  }

  *{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 1rem;
    }
  }




  /* Header */

  .n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
}
.n-left{
    display: flex;
    flex: 1;
    align-items: center;
    gap: 2rem;
}
.n-name{
    font-size: 1.3rem;
    font-weight: bold;
}
.n-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.n-list{
    flex: 10;
}
.n-list>ul{
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-right: 2rem;
}
.n-list>ul>li{
    list-style: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(0, 0, 0);
}
.n-list>ul>li:hover{
    cursor: pointer;
    color: var(--orange);
}
.n-list>ul>li>.link-1, .link-2, .link-3, .link-4, .link-5{
  text-decoration: none;
  color: black;
}
.n-list>ul>li>.link-1:hover, .link-2:hover, .link-3:hover, .link-4:hover, .link-5:hover{
  text-decoration: none;
  color: var(--orange);
}
.n-button{
    flex: 2;
}

@media screen and (max-width:480px) {
    .n-list{
    display: none;
    }
    .n-button{
        display: none;
    }
}